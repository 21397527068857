export const education = [
  {
    eduDate: "2014",
    eduTitle: "Bachelor of Engineering",
    eduIns: "Nagpur University",
    eduDesc: "Earned a Bachelor of Engineering degree in the Computer Engineering program.",
  },
  {
    eduDate: "2007",
    eduTitle: "Higher Secondary Education",
    eduIns: "Maharashtra State Board",
    eduDesc: "I have completed my Higher Secondary schooling with good grades in Science stream.",
  },
  {
    eduDate: "2005",
    eduTitle: "School Certificate",
    eduIns: "Maharashtra State Board",
    eduDesc: "I have completed my Primary schooling with good grades.",
  },
  {
    eduDate: "2008 - 2012",
    eduTitle: "Bachelor of Science",
    eduIns: "TM Primary School",
    eduDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];
