import React from 'react';
import { ReactComponent as JavaScriptIcon } from "../lib/icons/JavaScript.svg";
import { ReactComponent as HtmlIcon } from "../lib/icons/Html.svg";
import { ReactComponent as PhotoshopIcon } from "../lib/icons/Photoshop.svg";
import { ReactComponent as WordPressIcon } from "../lib/icons/WordPress.svg";
import { ReactComponent as LaravelIcon } from "../lib/icons/laravel-2.svg";
import { ReactComponent as NodeJSIcon } from "../lib/icons/nodejs-icon.svg";
import { ReactComponent as MongoDBIcon } from "../lib/icons/mongodb-icon-2.svg";
import { ReactComponent as ReactJSIcon } from "../lib/icons/react-js.svg";

export const skills = [
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <LaravelIcon className="h-10 w-10"></LaravelIcon>,
    skillTitle: "Laravel",
    skillDesc: "The best knowledge and practical experience for creating and managing any application.",
    skillPercent: "90%",
  },
  {
    skillBox: "pb-10 pt-10 sm:pb-5 sm:pt-10 sm:pr-10 border-r-0 sm:border-r-[1px] sm:border-b-0 border-b-[1px] border-[#9bb1cf]",
    skillIcon: <NodeJSIcon className="h-10 w-10"></NodeJSIcon>,
    skillTitle: "Node JS",
    skillDesc: "Excellent understanding to work with any applications that arise with this technology.",
    skillPercent: "75%",
  },
  {
    skillBox: "pb-10 sm:pt-5 sm:pb-10 sm:pr-10 border-r-0 sm:border-r-[1px] border-b-[1px] border-[#9bb1cf]",
    skillIcon: <ReactJSIcon className="h-10 w-10"></ReactJSIcon>,
    skillTitle: "React JS",
    skillDesc: "Like creating UI and integrations that elevate applications to new heights.",
    skillPercent: "75%",
  },
  {
    skillBox: "pb-10 pt-10 sm:pt-5 sm:pb-10 sm:pl-10 border-b-[1px] border-[#9bb1cf]",
    skillIcon: <MongoDBIcon className="h-10 w-10"></MongoDBIcon>,
    skillTitle: "Mongo DB",
    skillDesc: "My favorite database for handling both big and small applications to work with the API's.",
    skillPercent: "80%",
  },
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <WordPressIcon className="h-10 w-10"></WordPressIcon>,
    skillTitle: "WordPress",
    skillDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    skillPercent: "80%",
  },
];
