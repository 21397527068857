import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles2 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Education2 } from "../../components/Education";
import { FreelanceWork3 } from "../../components/FreelanceWork";
import { Partners2 } from "../../components/Partners";
import { SocialLinks5 } from "../../components/SocialLinks";

import { ReactComponent as CheckMark } from "../../lib/icons/CheckMark.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../../lib/icons/Curve.svg";

import PatternImg2 from "../../assets/images/patternImg2.jpg";
import ServImg1 from "../../assets/images/resources/servDetailImg1-1.jpg";
import ServImg2_1 from "../../assets/images/resources/servDetailImg2-1.jpg";

const Education = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Education</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Education"
        hometitle="Home"
        homeURL="/"
        currentPage="Education"
      ></PageTitle>
      {/* <Education2></Education2> */}
      <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                My Education Journey
              </h3>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              My B.E. education laid a solid foundation in engineering principles, equipping me with the technical skills necessary to tackle complex 
              engineering challenges and innovate solutions in my field. The rigorous curriculum and hands-on projects honed my critical thinking and 
              problem-solving abilities, teaching me to approach problems methodically and creatively.  Through numerous group projects and lab assignments, 
              I learned the importance of teamwork and collaboration, skills that are essential in the multidisciplinary nature of engineering work.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              The fast-paced and ever-evolving field of engineering taught me the importance of adaptability and the need for continuous learning to stay 
              abreast of the latest technologies and methodologies. Through leadership roles in academic projects and student organizations, I developed 
              essential leadership and project management skills, preparing me to lead projects and teams effectively in my future career. The challenges 
              and setbacks faced during my B.E. journey taught me resilience and perseverance, qualities that are crucial for overcoming obstacles and achieving 
              long-term goals.
              </p>
            </div>
            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Bachelor of Engineering (B.E/B.Tech)
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">University:</strong> <span>Nagpur University</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Year:</strong> <span>2014</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Collage:</strong> <span>Vilasrao Deshmukh Collage of Engineering and Technology, Mouda, Nagpur</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Stream:</strong> <span>Computer Engineering</span>
                </p>
              </div>
              {/* <div className="servDetailImage relative rounded-[10px] sm:rounded-[1.25rem] md:rounded-[3.125rem] overflow-hidden w-full">
                <img className="w-full" src={ServImg2_1} alt=""></img>
              </div> */}
            </div>

            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Higher Secondary Education (12th)
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Board:</strong> <span>Maharashtra State Board</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Year:</strong> <span>2007</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Collage:</strong> <span>Ujjwal Junior Collage, Nagpur</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Stream:</strong> <span>Science</span>
                </p>
              </div>
            </div>

            <div className="servDetailItem grid grid-cols-1 lg:grid-cols-2 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Secondary Education (10th)
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Board:</strong> <span>Maharashtra State Board</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Year:</strong> <span>2005</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">School:</strong> <span>Tajbaba Vidyalaya, Nagpur</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Language:</strong> <span>English</span>
                </p>
              </div>
            </div>
            {/* Service Detail */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default Education;
