import { ReactComponent as HomeIcon } from "../lib/icons/Home.svg";
import { ReactComponent as EnvelopeIcon } from "../lib/icons/Envelope.svg";
import { ReactComponent as PhoneIcon } from "../lib/icons/Phone.svg";

export const conInfo2 = [
  {
    conIcon: <HomeIcon className="h-[3.4375rem] fill-accent"></HomeIcon>,
    conTitle: "Address:",
    conCap: "63, Mhalgi Nagar, Nagpur, Maharashtra",
  },
  {
    conIcon: <PhoneIcon className="h-[3.4375rem] fill-accent"></PhoneIcon>,
    conTitle: "Mobile:",
    conCap: "+91-9422115617",
  },
  {
    conIcon: <EnvelopeIcon className="h-[3.4375rem] fill-accent"></EnvelopeIcon>,
    conTitle: "Email Address:",
    conCap: "ashwin.nextz@gmail.com",
  },
];
