import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar } from "../../components/BottomBar";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles2 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Portfolio2 } from "../../components/Portfolio";
import { Services2 } from "../../components/Services";
import { MyExperienceAndSkills } from "../../components/MyExperienceAndSkills";
import { Education2 } from "../../components/Education";
import { FreelanceWork3 } from "../../components/FreelanceWork";
import { Partners2 } from "../../components/Partners";
import { SocialLinks5 } from "../../components/SocialLinks";

import { ReactComponent as CheckMark } from "../../lib/icons/CheckMark.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../../lib/icons/Curve.svg";

import PatternImg2 from "../../assets/images/patternImg2.jpg";
import ServImg1 from "../../assets/images/resources/servDetailImg1-1.jpg";
import ServImg2_1 from "../../assets/images/resources/servDetailImg2-1.jpg";
import { SectionTitle } from "../../components/SectionTitles";

const Projects = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Projects"
        hometitle="Home"
        homeURL="/"
        currentPage="My Projects"
      ></PageTitle>
      {/* <Education2></Education2> */}
      <section className="pageWrap py-[3rem] relative w-full">
        {/* <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                My Projects
              </h3>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                <ul className="ulInListStyle">
                  <li>Currently working as a Sr. PHP Laravel web Developer in Valethi Technologies, Nagpur since December 2021.</li>
                  <li>Strong knowledge in PHP, Laravel, MySQL, REST API’s, jQuery, Scripting languages, Bootstrap, HTML5, Data tables.</li>
                  <li>Experience in Requirements, Development, Implementation, Designing, Analysis, Database Schema and Maintenance of applications.</li>
                  <li>Good knowledge and experience in Scripting languages like JavaScript, React JS, Node JS, jQuery, Ajax</li>
                  <li>Easily work with complex, existing projects, dynamic websites, jQuery plugins, ReactJS packages, npm and CMS.</li>
                  <li>Friendly with Web hosting, cPanel, Gitlab, GitHub, Bitbucket, SourceTree.</li>
                  <li>7 years total of experience working as a software developer in the IT sector</li>
                </ul>
              </p>
            </div>
          </div>
        </div> */}
        {/* Page Wrap */}
      </section>
      <SectionTitle
        title="My Amazing"
        titleInner="Works"
        desc="Deep understanding of web technologies and dedication to implementing the latest coding standards have significantly elevated the quality of my projects. The way I tackle complex problems and come up with innovative solutions never ceases to amaze me. My problem-solving skills are a key asset to my team. I admire the ability to listen to and understand the client's needs, translating them into functional and aesthetically pleasing web solutions. My focus on client satisfaction is commendable."
      ></SectionTitle>
      <section className="pageWrap md:py-[3.5rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Diageo - Crown Royal USA & Captain Morgan drinks portal
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 8, Shopify, REST API’s, HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL, Amazon RDS</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It’s a web E-Comm based application where Customers order drinks from shopify e-comm
                    store and orders manage in the admin panel.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Development, Implementation, API development to integrate with Shopify store and admin.
                    Implemented Ideas, API integration to admin panel, Order desk API implementation, etc.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Visual studio</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Website: </strong> <span><a href="https://www.crownroyal.com" target="blank">https://www.crownroyal.com</a></span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Mastercard Shophub E-Comm
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 8, HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax. </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL, Amazon RDS</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It’s a web E-Comm based application i.e., Customers order products from e-comm store
                    and orders manage by admin with fully featured configurations panel.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Development, Implementation, configuration analysis.
                    Implemented Ideas, developed admin configuration panel, etc.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Payment Gateway: </strong> <span>Stripe, PayPal</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Visual studio</span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Micro Lending
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 8, HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax. </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It’s a web and has two users-based application i.e., Investors and Entrepreneurs/NGO.
                    In which Investors can make contract with Entrepreneurs after successful contract Investors send money to
                    NGO by online.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Development, Implementation, Technical ideas, Requirement Analysis.
                    Implemented Ideas, developed entire admin panel, etc.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Payment Gateway: </strong> <span>Razorpay</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Visual studio</span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Essayfessors
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 8, HTML5, CSS, Bootstrap, React JS, jQuery, JavaScript, Ajax.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It’s a web and has two users-based application i.e., Customers and Writers. In which
                    customers can post essay with suitable information and create orders, on other hand writers will bid on those
                    orders and customer can hire writer</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Development, Implementation, Technical ideas, Requirement Analysis.
                    Implemented Ideas, developed entire admin panel, etc.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Payment Gateway: </strong> <span>Stripe, PayPal.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Visual studio</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Website: </strong> <span><a href="https://iqwriters.com" target="blank">https://iqwriters.com</a></span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                OCR (Online Casino Reviews)
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 7, HTML5, CSS, Bootstrap, ReactJs, jQuery, JavaScript, Ajax.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>Its web-based application in which user can add/post online casino reviews.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Development, Implementation, Designer, Technical ideas, Requirement Analysis.
                  Implemented Ideas, Developed Admin panel, admin can add games,software, casino, etc.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Payment Gateway: </strong> <span>PayPal.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Visual studio</span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                Interim
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 7, HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It’s a web-based application in which customer can book apartments and cars on rent and admin view booking 
                    in their admin panel</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Worked as Backend and designerd some part of UI, Database management,
                  Decision maker, Technical ideas, Implemented Ideas, developed admin panel, etc
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Payment Gateway: </strong> <span>Stripe, PayPal</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Sublime Text</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Website: </strong> <span><a href="https://interimcity.com" target="blank">https://interimcity.com</a></span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                BnB for dogs
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 6 , HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It is a dog owner and dog carer users web-based application, in which dog carer care of dogs of
                  owners. Owner could book dog carer for caring of dogs if owner went to holidays.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Worked as Backend and designed some part of UI, Database management,
                  Decision maker, Technical ideas, Implemented Ideas, developed admin panel, etc
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Payment Gateway: </strong> <span>Stripe, PayPal</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Sublime Text</span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                MV Internationals
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 5, HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>India's inventory management system for imports and exports goods, furnitures, electronics, artifects, 
                    etc from China.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Worked as developer as well as worked on Requirement Analysis, Design, Database
                  management, admin panel, etc. I created the entire website.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Sublime Text</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Website: </strong> <span><a href="https://mvints.com" target="blank">https://mvints.com</a></span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                Pradnya Maitri Pratisthan
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It's a social work/NGO based website to share information about NGO, In which users can orders books 
                    and pay through online also users can make donations from the portal.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Worked on requirements analysis, implementation, design, technical concepts, 
                    database management, admin panel, etc. in addition to my job as a developer. The entire website was made by me.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Payment Gateway: </strong> <span>Razorpay</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Visual Studio</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Website: </strong> <span><a href="https://pmpdhamma.org" target="blank">https://pmpdhamma.org</a></span>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                BAGAP (Babasaheb Ambedkar Global Association of Professionals)
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Technologies: </strong> <span>PHP, Laravel 5, HTML5, CSS, Bootstrap, jQuery, JavaScript, Ajax</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Database: </strong> <span>MySQL</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Synopsis: </strong> <span>It's a social work/NGO based website to share information about NGO, In which users can orders books 
                    and pay through online also users can make donations from the portal.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Roles and Responsibilities: </strong> <span>Worked on requirements analysis, implementation, design, technical concepts, 
                    database management, admin panel, etc. in addition to my job as a developer. The entire website was made by me.
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>IDE: </strong> <span>Sublime Text</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong>Website: </strong> <span><a href="https://bagap.org/public" target="blank">https://bagap.org/public</a></span>
                </p>
              </div>




            </div>
            {/* Service Detail */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <section className="">
        <ContactUs></ContactUs>
      </section>

      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default Projects;
