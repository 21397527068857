import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SmoothScroll from "../components/SmoothScroll/SmoothScroll";
import { BlogDetail, BlogPage, BlogPage2 } from "../pages/BlogPages";
import { ContactPage } from "../pages/ContactPage";
import { ErrorPage } from "../pages/ErrorPage";
import { HomePage, HomePage2 } from "../pages/HomePages";
import { PlansPage } from "../pages/PlansPage";
import { PortfolioDetail, PortfolioPage, PortfolioPage2, PortfolioPage3 } from "../pages/PortfolioPages";
import { ServiceDetail, ServicePage } from "../pages/ServicePages";
import { Education } from "../pages/EducationPage";
import AboutMe from "../pages/AboutMePage/AboutMe";
import Skills from "../pages/SkillsPage/Skills";
import Projects from "../pages/ProjectPages/Projects";

const RouterLinks = () => {
  return (
    <>
      <Router>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<HomePage></HomePage>} />
            <Route path="/home" element={<HomePage2></HomePage2>} />
            <Route path="/education" element={<Education></Education>} />
            <Route path="/about-me" element={<AboutMe></AboutMe>} />
            <Route path="/skills" element={<Skills></Skills>} />
            <Route path="/projects" element={<Projects></Projects>} />
            {/* <Route path="/blog" element={<BlogPage></BlogPage>} /> */}
            {/* <Route path="/blog/:Id" element={<BlogDetail></BlogDetail>} /> */}
            {/* <Route path="/blog2" element={<BlogPage2></BlogPage2>} /> */}
            {/* <Route path="/plans" element={<PlansPage></PlansPage>} /> */}
            <Route path="/services" element={<ServicePage></ServicePage>} />
            <Route path="/service/:Id" element={<ServiceDetail></ServiceDetail>} />
            {/* <Route path="/portfolio" element={<PortfolioPage></PortfolioPage>} /> */}
            {/* <Route path="/portfolio/:Id" element={<PortfolioDetail></PortfolioDetail>} /> */}
            {/* <Route path="/portfolio2" element={<PortfolioPage2></PortfolioPage2>} /> */}
            {/* <Route path="/portfolio3" element={<PortfolioPage3></PortfolioPage3>} /> */}
            <Route path="/contact" element={<ContactPage></ContactPage>} />
            <Route path="/404" element={<ErrorPage></ErrorPage>} />
            <Route path="*" element={<ErrorPage></ErrorPage>} />
          </Routes>
        </SmoothScroll>
      </Router >
    </>
  );
};

export default RouterLinks;
