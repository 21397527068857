import React, { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.name == "" || formData.email == "" || formData.message == "" ) {
      return toast.error("Please fill all the fields!", { theme: "colored" });
    }

    const checkEmail = validator.isEmail(formData.email);
    if(checkEmail == false)
    {
        return toast.error("Please enter valid email address!",{theme: "colored"});
    }

    if(validator.isEmpty(formData.name) == true)
    {
        return toast.error("Please enter name!",{theme: "colored"});
    }

    if(validator.isEmpty(formData.message) == true)
    {
        return toast.error("Please enter message!",{theme: "colored"});
    }

      try {
        const response = await fetch('https://ashwinmeshram.com/email.php', {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();
        if(data.status == "success")
        {
          toast.success("Your message has been sent successfully!",{theme: "colored"});
          e.target.reset();
          let emptyvals = { name: "", email: "", message: "" };
          setFormData(emptyvals);
        }
        e.target.reset();
        // Handle success
      } catch (error) {
        toast.error("Error while sending your message! Please try again.",{theme: "colored"});
        // console.error('Error:', error);
        // Handle error
      }

  };


  return (
    <div className="contactFormWrap relative w-full">
      <form
        className="grid gap-[1.875rem] md:grid-cols-2 grid-cols-1"
        onSubmit={handleSubmit}
      >
        
        <div className="fieldBox w-full col-span-2">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Your Name *
          </label>
          <input
            type="text"
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            name="name"
            value={formData.name}
            onChange={handleChange}
          ></input>
        </div>
        <div className="fieldBox w-full col-span-2">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Your Email *
          </label>
          <input
            type="text"
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
            name="email"
            value={formData.email}
            onChange={handleChange}
          ></input>
        </div>
        <div className="fieldBox w-full col-span-2">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Write a Message *
          </label>
          <textarea
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[4rem] md:h-[8.125rem] p-[1.125rem] text-white"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="fieldbtn mt-0 w-full col-span-2">
          <button
            className="bg-white text-accent text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent2 hover:text-white text-center inline-block"
            type="submit"
          >
            Contact Me
          </button>
          <ToastContainer />
        </div>
      </form>
      {/* Contact Form Wrap */}
    </div>
  );
};

export default ContactForm;
