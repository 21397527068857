import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar } from "../../components/BottomBar";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles2 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Education2 } from "../../components/Education";
import { FreelanceWork3 } from "../../components/FreelanceWork";
import { Partners2 } from "../../components/Partners";
import { SocialLinks5 } from "../../components/SocialLinks";

import { ReactComponent as CheckMark } from "../../lib/icons/CheckMark.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../../lib/icons/Curve.svg";

import PatternImg2 from "../../assets/images/patternImg2.jpg";
import ServImg1 from "../../assets/images/resources/servDetailImg1-1.jpg";
import ServImg2_1 from "../../assets/images/resources/servDetailImg2-1.jpg";

const AboutMe = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>About Me</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="About Me"
        hometitle="Home"
        homeURL="/"
        currentPage="About Me"
      ></PageTitle>
      {/* <Education2></Education2> */}
      <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                About Me
              </h3>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              My self Ashwin Naresh Meshram, and I graduated from Vilasrao Deshmukh College of Engineering and Technology with a Bachelor of Engineering in 
              the Computer Engineering program. In my home, there are five people. As an accomplished, knowledgeable, and aspiring entrepreneur 
              in the IT sector, I am enthusiastic about my work with the Strong analytical and problem solving skills.
              </p>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
              In order to use my technological expertise and experience to further the organization's performance, I am hoping to land a difficult IT role. 
              I'm looking for a position where I can work with a team to solve challenging technical problems while advancing my abilities in project 
              management and software development.
              </p>
            </div>
            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Personal Details
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Name:</strong> <span>Ashwin Naresh Meshram</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Gender:</strong> <span>Male</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Date of Birth:</strong> <span>24/05/1990</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Age:</strong> <span>33</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Marrital Status:</strong> <span>Married</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Languages Known:</strong> 
                  <div>Marathi (Speak, Read, Write)</div>
                  <div>Hindi (Speak, Read, Write)</div>
                  <div>English (Speak, Read, Write)</div>
                </p>
              </div>
            </div>

            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Education Details
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Bachelor of Engineering (B.E/B.Tech):</strong> <span>2014</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Higher Secondary Education (12th):</strong> <span>2007</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Secondary Education (10th):</strong> <span>2005</span>
                </p>
              </div>
            </div>

            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Family Details
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Father:</strong> <span>Naresh C. Meshram</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Mother:</strong> <span>Pratibha N. Meshram</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Wife:</strong> <span>Bharti A. Meshram</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Son:</strong> <span>Ayansh A. Meshram</span>
                </p>
              </div>
            </div>

            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Contact Details
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Primary Mobile & WhatsApp:</strong> <span>+91-9422115617</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Secondary Mobile & WhatsApp:</strong> <span>+91-9960806983</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Email:</strong> <span>ashwin.nextz@gmail.com</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Address:</strong> <span>63, Mhalgi Nagar, Nagpur</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Pincode:</strong> <span>440034</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">City:</strong> <span>Nagpur</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">State:</strong> <span>Maharashtra</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Country:</strong> <span>India</span>
                </p>
              </div>
            </div>

            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Social Details
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">LinkedIn:</strong> <span>Ashwin Naresh Meshram - 
                    <a href="https://www.linkedin.com/in/ashwin-meshram-083b2a150" target="blank">https://www.linkedin.com/in/ashwin-meshram-083b2a150</a>
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Facebook:</strong> <span>Ashwin Naresh Meshram - 
                    <a href="https://www.facebook.com/ashwinkk" target="blank">https://www.facebook.com/ashwinkk</a>
                  </span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">YouTube:</strong> <span>Coder EvolutionGuy - 
                    <a href="https://www.youtube.com/@coderevolutionguy2792" target="blank">https://www.youtube.com/@coderevolutionguy2792</a>
                  </span>
                </p>
              </div>
            </div>

            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Work Experience
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Valethi Technologies:</strong> <span>Sr. PHP Laravel web/software developer 
                    (Have been working since December 2021)</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">LeoCoders Pvt. Ltd.:</strong> <span>Sr. PHP Laravel web/software developer 
                    (Jan 2021 to Nov 2021)</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Alkurn Technologies:</strong> <span>: Laravel Full stack web/software developer (Sep 2019 to Jan 2021)</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Vowel web:</strong> <span>: Laravel Fullstack web/software developer (Nov 2017 to Sep 2019)</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <strong className="font-bold">Nextz Software:</strong> <span>: Laravel Fullstack web/software developer (Jan 2017 to Nov 2017)</span>
                </p>
              </div>
            </div>

            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Personal Skills
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Strong analytical and problem solving skills.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Time management and prioritization skills.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Ability to work independently & in a team environment.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Highly adaptive and flexible.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Reliable, motivated, hardworking and attentive to details.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Quality oriented and clear design approach.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Self-started attitude.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Passionate programmer.</span>
                </p>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <span>Able to organize my work and bring results efficiently.</span>
                </p>
              </div>
            </div>
            {/* Service Detail */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default AboutMe;
