import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar } from "../../components/BottomBar";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles2 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { Services2 } from "../../components/Services";
import { MyExperienceAndSkills } from "../../components/MyExperienceAndSkills";
import { Education2 } from "../../components/Education";
import { FreelanceWork3 } from "../../components/FreelanceWork";
import { Partners2 } from "../../components/Partners";
import { SocialLinks5 } from "../../components/SocialLinks";

import { ReactComponent as CheckMark } from "../../lib/icons/CheckMark.svg";
import { ReactComponent as UiCreativeDesignIcon } from "../../lib/icons/Curve.svg";

import PatternImg2 from "../../assets/images/patternImg2.jpg";
import ServImg1 from "../../assets/images/resources/servDetailImg1-1.jpg";
import ServImg2_1 from "../../assets/images/resources/servDetailImg2-1.jpg";

const Skills = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Skills</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Skills"
        hometitle="Home"
        homeURL="/"
        currentPage="My Skills"
      ></PageTitle>
      {/* <Education2></Education2> */}
      <section className="pageWrap py-[5rem] relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                My Functional Skills
              </h3>
              <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                <ul className="ulInListStyle">
                  <li>Currently working as a Sr. PHP Laravel web Developer in Valethi Technologies, Nagpur since December 2021.</li>
                  <li>Strong knowledge in PHP, Laravel, MySQL, REST API’s, jQuery, Scripting languages, Bootstrap, HTML5, Data tables.</li>
                  <li>Experience in Requirements, Development, Implementation, Designing, Analysis, Database Schema and Maintenance of applications.</li>
                  <li>Good knowledge and experience in Scripting languages like JavaScript, React JS, Node JS, jQuery, Ajax</li>
                  <li>Easily work with complex, existing projects, dynamic websites, jQuery plugins, ReactJS packages, npm and CMS.</li>
                  <li>Friendly with Web hosting, cPanel, Gitlab, GitHub, Bitbucket, SourceTree.</li>
                  <li>7 years total of experience working as a software developer in the IT sector</li>
                </ul>
              </p>
            </div>
            {/* Service Detail */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <section>
        <Services2></Services2>
      </section>
      <section className="">
        <MyExperienceAndSkills></MyExperienceAndSkills>
      </section>
      <section className="pageWrap py-[5rem] md:py-[5.5rem] lg:py-[6.875rem] mt-10 relative w-full">
        <div className="container sm:container md:container lg:container xl:max-w-[73.125rem] mx-auto">
          <div className="servDetail relative w-full">
            <div className="servDetailDesc flex flex-col gap-6 w-full">
              <h3 className="text-accent2 font-Poppins font-bold text-[1.75rem] md:text-[2.25rem]">
                Technical Skills I Do For You
              </h3>
            </div>
            <div className="servDetailItem grid grid-cols-1 gap-9 w-full mt-[3.75rem]">
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Server languages
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>PHP</li>
                    <li>Node JS</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Frameworks
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Laravel 5,6,7,8,9,10</li>
                    <li>Express JS</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Web Technologies
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>HTML5</li>
                    <li>CSS</li>
                    <li>Bootstrap</li>
                    <li>Ant Design</li>
                    <li>Material UI</li>
                    <li>Rest API’s</li>
                    <li>Soap API’s</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Scripting Languages
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>JavaScript</li>
                    <li>React JS</li>
                    <li>Node JS</li>
                    <li>Express JS</li>
                    <li>JSON</li>
                    <li>jQuery</li>
                    <li>Ajax</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Databases
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>MySQL</li>
                    <li>Mongo DB</li>
                    <li>PhpMyAdmin</li>
                    <li>Amazon RDS</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Design Pattern
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>LAMP Pattern</li>
                    <li>MERN Stack</li>
                    <li>MVC</li>
                    <li>Core</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Testing
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Laravel Unit Testing</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Web/Application server
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Wamp</li>
                    <li>Xampp</li>
                    <li>Node Server</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Web tools
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>cPanel</li>
                    <li>Gitlab</li>
                    <li>GitHub</li>
                    <li>Bitbucket</li>
                    <li>SourceTree</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  IDE
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Visual Studio Code</li>
                    <li>Sublime Text</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Tools
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Photoshop</li>
                    <li>FileZilla</li>
                    <li>Putty</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Payment Gateway
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>PayPal</li>
                    <li>Stripe</li>
                    <li>Razorpay</li>
                    <li>Paytm</li>
                    <li>PayU Money</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Operating Systems
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Windows 7/8/10/11</li>
                    <li>Linux</li>
                    <li>Mac</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Office Tools
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Microsoft Office</li>
                    <li>Libre Office</li>
                  </ul>
                </p>
              </div>
              <div className="servDetailDesc flex flex-col gap-4 w-100">
                <h4 className="text-accent2 font-Poppins font-bold text-[1.325rem] md:text-[1.625rem]">
                  Internet
                </h4>
                <p className="text-desc2 font-NunitoSans font-normal text-[1rem] md:text-[1.125rem] leading-relaxed">
                  <ul className="ulInListStyle">
                    <li>Browsing Internet</li>
                    <li>Social Surffing</li>
                    <li>Tech News</li>
                  </ul>
                </p>
              </div>



            </div>
            {/* Service Detail */}
          </div>
        </div>
        {/* Page Wrap */}
      </section>
      <section className="">
        <ContactUs></ContactUs>
      </section>

      <BottomBar></BottomBar>
    </React.Fragment>
  );
};

export default Skills;
