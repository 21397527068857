import React from "react";
import { Helmet } from "react-helmet";
import { BottomBar2 } from "../../components/BottomBar";
import { ContactInfoProfiles2 } from "../../components/ContactInfoProfiles";
import { ContactUs } from "../../components/ContactUs";
import { Header2 } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";

const ContactPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <Header2></Header2>
      <PageTitle
        title="Contact Me"
        hometitle="Home"
        homeURL="/"
        currentPage="Contact Me"
      ></PageTitle>
      <section className="pageWrap relative w-full">
        <iframe className="w-full relative border-0 -mt-[8.125rem] min-h-[35rem] sm:min-h-[45rem] md:min-h-[60.3125rem]"
          src="https://www.google.com/maps/embed/v1/place?q=Mhalgi+Nagar+Square+Water+Bill+Filling+Station,+Sanmarga+Nagar,+Nagpur,+Maharashtra&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          allowFullScreen
          loading="lazy" title="Contact Map"
        ></iframe>
        {/* Page Wrap */}
      </section>
      <ContactUs></ContactUs>
      {/* <ContactInfoProfiles2></ContactInfoProfiles2> */}
      <BottomBar2></BottomBar2>
    </React.Fragment>
  );
};

export default ContactPage;
